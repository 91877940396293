module.exports = {
  siteTitle: 'Point50 Capital', // <title>
  manifestName: 'Point50',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Point 50 Capital',
  subHeading: 'Decentralized Exchange and Peer to Peer fiat and crypto Trading ',
  // social
  socialLinks: [
    {
      icon: 'fa-linkedin',
      name: 'LinkedIn',
      url: 'https://linkedin.com/company/Point50Cap',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/bitlipa',
    },
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://facebook.com/bitlipa',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'info@point50capital.io',
    },
  ],
  address: 'Nairobi, Kenya',
};
